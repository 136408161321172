<template>
    <div class="about">
        <v-header @popPersonal="personalFn" />
        <v-sidebar/>
        <div class="content-box" :class="{'content-collapse':collapse}">
            <div class="content">
                <transition name="move" mode="out-in">
                    <router-view />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import vHeader from '@/components/common/Header'
    import vSidebar from '@/components/common/Sidebar'
    export default {
        name: "Home",
        components:{
            vHeader,
            vSidebar,
        },
        methods:{
            // 弹出个人资料框
            personalFn(){
                //
            },
        },
        computed:{
            //监听折叠
            collapse(){
                return this.$store.state.collapse;
            },
        },
    }
</script>

<style scoped>

</style>
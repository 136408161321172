import Vue from 'vue'
import Vuex from 'vuex'

import router from '@/router/'

Vue.use(Vuex)

import axios from '../axios/'

export default new Vuex.Store({
    state:{
        collapse:false, //折叠
        loginStatue:false,  //登录状态
        userInfo:undefined, //用户信息
        picBaseUrl:'http://www.hagonne.com/images/website/',    //图片前缀(正式)
        tmp_picBaseUrl:'http://www.hagonne.com/tmp_resources/',    //图片前缀(临时),用于编辑产品、方案、新闻时tinymce插入图片所使用。
    },
    mutations:{
        //设置登录状态
        setLoginState(state,data){
            state.LoginStatue = data;
        },
        //设置用户信息
        setUserInfo(state,data){
            state.userInfo = data;
        },
        // 侧边栏折叠
        hadndleCollapse(state, data) {
            state.collapse = data;
        },
    },
    actions:{
        // 侧边栏折叠
        hadndleCollapse({commit}, data) {
            commit('hadndleCollapse',data);
        },
        //登录成功
        async login({commit},obj){
            localStorage.setItem('loginStatus',true);
            commit('setLoginState',true);
            commit('setUserInfo',obj);
            router.push('/');
        },
        //获取用户信息
        async getUserInfo({commit}){
            const {loginStatus} = localStorage;
            if(loginStatus){
                try{
                    const {data} = await axios('/api/account/username');
                    if(data.code){
                        commit('setLoginState',true);
                        commit('setUserInfo',data.data);
                    }else{
                        commit('setLoginState',false);
                        commit('setUserInfo',undefined);
                    }
                }catch (e) {
                    commit('setLoginState',false);
                    commit('setUserInfo',undefined);
                    delete localStorage.loginStatus;
                    console.log('store中获取用户信息失败');
                }
            }
        },
        //退出登录
        async logout({commit}){
            delete localStorage.loginStatus;
            commit('setLoginState',false);
            commit('setUserInfo',undefined);
        },
    },
    getters:{
        //获取登录状态
        logined(state){
            return state.LoginStatue;
        },
        //获取用户信息
        getUserInfo(state){
            return state.userInfo;
        },
        //获取折叠状态
        getCollapse(state){
            return state.collapse;
        },
        //获取图片前缀
        getPicBaseUrl(state){
            return state.picBaseUrl;
        },
        //获取临时图片地址
        getTmpPicBaseUrl(state){
            return state.tmp_picBaseUrl;
        },
    },
})
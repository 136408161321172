import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);

import store from '@/store/'

import Home from '@/views/Home'

let routes = [
    {
        path:'/',
        redirect:'/dashboard',
    },
    {
        path:'/',
        name:'home',
        component:() => import('@/views/Home'),
        children:[
            {
                path:'/dashboard',
                name:'dashboard',
                meta:{
                    title:'系统首页'
                },
                component:() => import('@/views/Dashboard'),
            },
            {
                path:'/profile',
                name:'profile',
                meta:{
                    title:'公司信息管理'
                },
                component:() => import('@/views/Profile'),
            },
            {
                path:'/companyprofile',
                name:'companyprofile',
                meta:{
                    title:'公司简介'
                },
                component:() => import('@/views/CompanyProfile'),
            },
            {
                path:'/banners',
                name:'banners',
                meta:{
                    title:'轮播管理'
                },
                component:() => import('@/views/Banners'),
            },
            {
                path:'/navs',
                name:'navs',
                meta:{
                    title:'导航菜单'
                },
                component:() => import('@/views/Navs'),
            },
            {
                path:'/producttype',
                name:'producttype',
                meta:{
                    title:'产品类别'
                },
                component:() => import('@/views/ProductType'),
            },
            {
                path:'/productall',
                name:'productall',
                meta:{
                    title:'产品列表'
                },
                component:() => import('@/views/ProductAll'),
            },
            {
                path:'/plantype',
                name:'plantype',
                meta:{
                    title:'方案类别'
                },
                component:() => import('@/views/PlanType'),
            },
            {
                path:'/planall',
                name:'planall',
                meta:{
                    title:'方案列表'
                },
                component:() => import('@/views/PlanAll'),
            },
            {
                path:'/newstype',
                name:'newstype',
                meta:{
                    title:'新闻类别'
                },
                component:() => import('@/views/NewsType'),
            },
            {
                path:'/newsall',
                name:'newsall',
                meta:{
                    title:'新闻列表'
                },
                component:() => import('@/views/NewsAll'),
            },
            {
                path:'/contact',
                name:'contact',
                meta:{
                    title:'用户留言'
                },
                component:() => import('@/views/Contact'),
            },
            /*{
                path:'/test',
                name:'test',
                meta:{
                    title:'测试'
                },
                component:() => import('@/views/test'),
            },*/
            {
                path:'*',
                redirect:'/',
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        meta: {
            title: '登录'
        },
        component: () => import ("@/views/Login")
    },
];

const router = new Router({
    mode:'history',
    routes,
});

router.beforeEach((to,from,next)=>{
    setTimeout(()=>{
        if (to.name !== 'login' && !store.state.LoginStatue){ //当前路径不是:Login 并且 vuex中未登录 =》跳转到登录页
            router.push({name: 'login'});
        }else if(to.name == 'login' && store.state.LoginStatue){  //当前路径是: Login 并且 vuex中已登录 =》跳转到首页
            router.push({name: 'home'});
        }
        next();
    },500);
});

export default router;
<template>
    <div class="sidebar">
        <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#324157" text-color="#bfcbd9" active-text-color="#20a0ff" unique-opened router>
            <template v-for="item in navs">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <!--目前没用到这个<2级下面还有3级菜单>-->
                            <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                                <template #title>{{ subItem.title }}</template>
                                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">{{ threeItem.title }}</el-menu-item>
                            </el-submenu>
                            <!--目前用的这个-->
                            <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{ subItem.title }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
    export default {
        name: "Sidebar",
        data(){
            return{
                navs: [
                    {
                        icon: "el-icon-s-home",
                        index: "dashboard",
                        title: "首页"
                    },
                    {
                        icon: "el-icon-document",
                        index: "profile",
                        title: "公司信息管理"
                    },
                    {
                        icon: "el-icon-document",
                        index: "companyprofile",
                        title: "公司简介"
                    },
                    {
                        icon: "el-icon-picture",
                        index: "banners",
                        title: "首页轮播管理"
                    },
                    {
                        icon: "el-icon-menu",
                        index: "nav",
                        title: "导航管理",
                        subs:[
                            {
                                index: "navs",
                                title: "导航菜单"
                            },
                        ],
                    },
                    {
                        icon: "el-icon-present",
                        index: "product",
                        title: "产品管理",
                        subs:[
                            {
                                index: "producttype",
                                title: "产品类别"
                            },
                            {
                                index: "productall",
                                title: "产品列表"
                            }
                        ],
                    },
                    {
                        icon: "el-icon-s-claim",
                        index: "plan",
                        title: "方案管理",
                        subs:[
                            {
                                index: "plantype",
                                title: "方案类别"
                            },
                            {
                                index: "planall",
                                title: "方案列表"
                            }
                        ],
                    },
                    {
                        icon: "el-icon-platform-eleme",
                        index: "news",
                        title: "新闻中心",
                        subs:[
                            {
                                index: "newstype",
                                title: "新闻类别"
                            },
                            {
                                index: "newsall",
                                title: "新闻列表"
                            }
                        ],
                    },
                    {
                        icon: "el-icon-chat-dot-round",
                        index: "contact",
                        title: "用户留言"
                    },
                    /*{
                        icon: "el-icon-info",
                        index: "test",
                        title: "测试"
                    },*/
                ],
            }
        },
        created(){},
        computed: {
            onRoutes() {
                return this.$route.path.replace("/", "");
            },
            collapse(){
                return this.$store.state.collapse;
            }
        },
    }
</script>

<style scoped>
    .sidebar {
        display: block;
        position: absolute;
        left: 0;
        top: 70px;
        bottom: 0;
        overflow-y: scroll;
    }
    .sidebar::-webkit-scrollbar {
        width: 0;
    }
    .sidebar-el-menu:not(.el-menu--collapse) {
        width: 250px;
    }
    .sidebar > ul {
        height: 100%;
    }
</style>
<template>
  <router-view />
</template>

<script>
  export default {
      name:'App',
      async created(){
          this.$store.dispatch('getUserInfo');
      },
  }
</script>

<style>
  /*放公共CSS文件*/
  @import "./assets/css/main.css";
  @import "./assets/css/color-dark.css";
</style>
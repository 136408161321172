<template>
    <div class="header">
        <div class="logo">
            <img class="pic1" src="../../assets/imgs/哈工精密(图形)白色.png" alt="" />
            <img class="pic2" src="../../assets/imgs/哈工精密(文字)白色.png" alt="" />
            <span> - 后台管理</span>
        </div>
        <!-- 折叠按钮 -->
        <div class="collapse-btn" @click="collapseChage">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
        </div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 消息中心 -->
                <!--<div class="btn-bell" @click="$emit('popWarning')">-->
                    <!--<el-tooltip effect="dark" :content="message?`预警消息`:`预警中心`" placement="bottom">-->
                        <!--<router-link to="">-->
                            <!--<i class="el-icon-bell"></i>-->
                        <!--</router-link>-->
                    <!--</el-tooltip>-->
                    <!--<span class="btn-bell-badge" v-if="message"></span>-->
                <!--</div>-->
                <!-- 用户头像 -->
                <div class="user-avator">
                    <img src="../../assets/imgs/img.jpg" />
                </div>
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{userInfo.username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="personal">个人资料</el-dropdown-item>
                            <el-dropdown-item divided command="loginOut">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        data(){
            return{
                message:2,
            }
        },
        mounted() {
            if (document.body.clientWidth < 1500) {
                this.collapseChage();
            }
        },
        methods:{
            // 侧边栏折叠
            collapseChage() {
                this.$store.dispatch("hadndleCollapse", !this.collapse);
            },
            //点击退出系统
            async handleCommand(val){
                if(val=='loginOut'){
                    try{
                        const {data} = await this.$axios('/api/account/loginout');
                        if(data.code){
                            this.$notify({
                                title: '已退出系统',
                                type: 'success',
                                duration: 500
                            });
                            this.$store.dispatch('logout');
                            // this.$router.push({path:'/login'});

                            setTimeout(()=>{
                                location.reload();
                            },300);
                        }else{
                            this.$notify.error({
                                title: '网络错误，请稍后重试',
                            });
                        }
                    }catch (e) {
                        this.$notify.error({
                            title: '网络错误，请稍后重试',
                        });
                    }
                }else if(val=='personal'){
                    this.$emit('popPersonal');
                }
            },
        },
        computed:{
            collapse(){
                return this.$store.getters.getCollapse;
            },
            userInfo(){
                return this.$store.getters.getUserInfo;
            }
        },
    }
</script>

<style scoped>
    @font-face {
        font-family: 'test';
        src: url("../../assets/fonts/字魂35号-经典雅黑.ttf") format('truetype');
    }



    .header {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 70px;
        font-size: 22px;
        color: #fff;
    }
    .collapse-btn {
        float: left;
        padding: 0 21px;
        cursor: pointer;
        line-height: 70px;
    }
    .header .logo {
        float: left;
        width: 300px;
        line-height: 70px;
        padding-left: 50px;
    }
    .header .logo span{
        font-family: 'test';
        color: #ffffff;
        opacity: .9;
        position: relative;
        top: -4px;
    }
    .header .logo img{
        height: auto;
        vertical-align: middle;
    }
    .header .logo .pic1{
        width: 55px;
    }
    .header .logo .pic2{
        width: 90px;
        margin-left: 10px;
    }


    .header-right {
        float: right;
        padding-right: 50px;
    }
    .header-user-con {
        display: flex;
        height: 70px;
        align-items: center;
    }
    .btn-fullscreen {
        transform: rotate(45deg);
        margin-right: 5px;
        font-size: 24px;
    }
    .btn-bell,
    .btn-fullscreen {
        position: relative;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 15px;
        cursor: pointer;
    }
    .btn-bell-badge {
        position: absolute;
        right: 0;
        top: -2px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #f56c6c;
        color: #fff;
    }
    .btn-bell .el-icon-bell {
        color: #fff;
    }
    .user-name {
        /*margin-left: 10px;*/
    }
    .user-avator {
        margin-left: 20px;
    }
    .user-avator img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .el-dropdown-link {
        color: #fff;
        cursor: pointer;
    }
    .el-dropdown-menu__item {
        text-align: center;
    }
</style>
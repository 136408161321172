import Vue from 'vue'
import Axios from 'axios'

import store from '@/store'



/*
* 由于多功能校验仪需要测试，现修改了如下内容，完毕后恢复
*   1、router/index.js
*   2、components/common/Sidebar.vue
*
* */


const axios = Axios.create({
    // baseURL:'',
    baseURL:'https://admin.hagonne.com',
    // baseURL:'http://m4w5740443.zicp.fun',
    // baseURL:'http://159.75.230.241',
});

//请求拦截
axios.interceptors.request.use(config => {
    //
    return config;
});

//接收拦截
axios.interceptors.response.use(response => {
    if(response.data.code==999){    //用户未登录
        store.dispatch('logout');

        setTimeout(()=>{
            location.reload();
        },300);
    }
    return response;
});

axios.defaults.withCredentials = true;  //允许后端设置cookie
Vue.prototype.$axios = axios;

export default axios;
import Vue from 'vue'
import App from './App.vue'

import router from '@/router/'
import store from '@/store/'

import axios from '@/axios/'

import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUi)

import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'

// vue-tinymce样式
import 'tinymce/skins/content/default/content.min.css'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'

// vue-tinymce主题
import 'tinymce/themes/silver'

// vue-tinymce插件
import 'tinymce/plugins/link'   //链接插件
import 'tinymce/plugins/image'   //图片插件
import 'tinymce/plugins/media'   //媒体插件
import 'tinymce/plugins/table'   //表格插件
import 'tinymce/plugins/lists'   //列表插件
import 'tinymce/plugins/quickbars'   //快速栏插件
import 'tinymce/plugins/fullscreen'   //全屏插件

// 图标
import 'tinymce/icons/default/icons'

// vue-tinymce本地化
import './utils/tinymce/langs/zh_CN.js'

Vue.prototype.$tinymce = tinymce
Vue.use(VueTinymce)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')